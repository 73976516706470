html,
body,
#root {
    min-height: 100vh;
}

.side-menu {
    display: flex;
    flex-direction: column;
}

.header-style {
    display: flex;
    flex-direction: row-reverse;
}